//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { voucherReductionTypes, voucherTypes } from '@/utils/helpers/voucher-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { areDatesSame, formatDateWithTimezone } from '@/utils/helpers/date-time-helper'

export default {
  props: {
    voucher: {
      type: Object,
      required: true,
      default: () => ({
        type: '',
        reduction_type: '',
        date_start: '',
        date_end: ''
      })
    }
  },

  data () {
    return {
      circleHeight: 0
    }
  },

  computed: {
    selectedVoucherType () {
      return Object.values(voucherTypes).find(
        voucherType => voucherType.name === this.voucher.type
      )
    },

    cardVoucherAdditionalClass () {
      switch (this.voucher.type) {
        case voucherTypes.discount.name:
          return 'voucher-discount'
        case voucherTypes.freeShippingCost.name:
          return 'voucher-shipping'
        default:
          return 'voucher-discount'
      }
    },

    cardVoucherIcon () {
      if (!this.selectedVoucherType) { return '/img/icon/voucher-discount.svg' }

      return this.selectedVoucherType.icon
    },

    cardVoucherLabel () {
      if (!this.selectedVoucherType) { return voucherTypes.discount.cardLabel }

      return this.selectedVoucherType.cardLabel
    },

    cardVoucherReductionValue () {
      switch (this.voucher.reduction_type) {
        case voucherReductionTypes.amount.name:
          return formatRupiah(this.voucher.reduction_value || '', 'Rp')
        case voucherReductionTypes.percent.name:
          return (this.voucher.reduction_value || '0') + '%'
        default:
          return this.voucher.reduction_value || ''
      }
    },

    cardVoucherPeriod () {
      if (!this.voucher || !this.voucher.date_start || !this.voucher.date_end) {
        return '-'
      }

      let formattedDateStart = ''
      const formattedDateEnd = formatDateWithTimezone(this.voucher.date_end, {
        formatResult: 'DD MMM YYYY'
      })

      const isDateInSameYear = areDatesSame([this.voucher.date_start, this.voucher.date_end], 'year')

      if (isDateInSameYear) {
        formattedDateStart = formatDateWithTimezone(this.voucher.date_start, {
          formatResult: 'DD MMM'
        })
      } else {
        formattedDateStart = formatDateWithTimezone(this.voucher.date_start, {
          formatResult: 'DD MMM YYYY'
        })
      }

      return [formattedDateStart, formattedDateEnd].join(' - ')
    }
  },

  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.observeCardVoucher()
      }, 500)
    })
  },

  methods: {
    observeCardVoucher () {
      const observer = new ResizeObserver(this.calculateCardVoucherHeight)
      observer.observe(this.$refs.cardVoucher)
    },

    calculateCardVoucherHeight () {
      if (!this.$refs.cardVoucher) {
        return
      }

      const cardVoucher = this.$refs.cardVoucher
      const cardVoucherHeight = cardVoucher.clientHeight

      if (cardVoucherHeight > 0) {
        this.circleHeight = cardVoucherHeight * 0.22
      }
    }
  }
}
