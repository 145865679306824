import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2de972b1&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBanner: require('/usr/src/app/components/home/HomeBanner.vue').default,CategorySection: require('/usr/src/app/components/home/CategorySection.vue').default,FlashsaleSection: require('/usr/src/app/components/home/FlashsaleSection.vue').default,PromotionSection: require('/usr/src/app/components/home/PromotionSection.vue').default,HomeBannerPromotionBrand: require('/usr/src/app/components/home/HomeBannerPromotionBrand.vue').default,HomeBrandPartner: require('/usr/src/app/components/home/HomeBrandPartner.vue').default,ProductsBrand: require('/usr/src/app/components/home/ProductsBrand.vue').default,HomeBannerPromotion: require('/usr/src/app/components/home/HomeBannerPromotion.vue').default,NearestStore: require('/usr/src/app/components/home/NearestStore.vue').default,ProductsStore: require('/usr/src/app/components/home/ProductsStore.vue').default,PopularSearch: require('/usr/src/app/components/home/PopularSearch.vue').default,ProductsTrending: require('/usr/src/app/components/home/ProductsTrending.vue').default,PanintiExclusiveSection: require('/usr/src/app/components/home/PanintiExclusiveSection.vue').default,BestSellerProducts: require('/usr/src/app/components/home/BestSellerProducts.vue').default,NewestProducts: require('/usr/src/app/components/home/NewestProducts.vue').default,ParentinghubSection: require('/usr/src/app/components/home/ParentinghubSection.vue').default,RecommendationSection: require('/usr/src/app/components/home/RecommendationSection.vue').default,PopupHomepage: require('/usr/src/app/components/home/PopupHomepage.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default,ApplicationDescriptionSection: require('/usr/src/app/components/home/ApplicationDescriptionSection.vue').default})
