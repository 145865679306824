//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      articleLoaded: false
    }
  },
  mounted () {
  },
  methods: {
    handleDispatch (e) {
      this.articleLoaded = true
    }
  },
  head () {
    return {
      title: 'Paninti.com Belanja Retail Harga Grosir, Ajak Teman Bisa Hemat Lebih Banyak',
      meta: [
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            'Belanja retail perlengkapan bayi, ibu dan rumah tangga terlengkap di Paninti.com dengan harga grosir | Produk Kualitas Terbaik ✓ 100% Aman ✓ Pengiriman Terjamin'
        },
        {
          hid: 'description',
          name: 'description',
          content:
            'Belanja retail perlengkapan bayi, ibu dan rumah tangga terlengkap di Paninti.com dengan harga grosir | Produk Kualitas Terbaik ✓ 100% Aman ✓ Pengiriman Terjamin'
        },
        {
          hid: 'og:keywords',
          name: 'og:keywords',
          content:
            'Toko perlengkapan bayi, Kebutuhan bayi baru lahir, Perlengkapan rumah tangga, Perlengkapan bayi, Peralatan bayi lengkap, Peralatan dapur lengkap, Peralatan dapur lengkap dan harganya, Toko perlengkapan rumah tangga, Perlengkapan ibu hamil, Online baby shop Page Title: Belanja Perlengkapan Bayi, Ibu dan Kebutuhan Parenting Lainnya | Paninti Store'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'Toko perlengkapan bayi, Kebutuhan bayi baru lahir, Perlengkapan rumah tangga, Perlengkapan bayi, Peralatan bayi lengkap, Peralatan dapur lengkap, Peralatan dapur lengkap dan harganya, Toko perlengkapan rumah tangga, Perlengkapan ibu hamil, Online baby shop Page Title: Belanja Perlengkapan Bayi, Ibu dan Kebutuhan Parenting Lainnya | Paninti Store'
        }
      ],
      link: [{ rel: 'canonical', href: 'https://paninti.com' }],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org/',
            '@type': 'Organization',
            name: 'Paninti',
            url: 'https://paninti.com/',
            logo: {
              '@type': 'ImageObject',
              url: 'https://paninti.com/img/logo/logo-paninti.svg'
            },
            description:
              'Toko Perlengkapan Bayi, Ibu Hamil, dan Peralatan Masak',
            address: {
              '@type': 'PostalAddress',
              postalCode: '14240',
              streetAddress:
                'Rukan Sentra Bisnis Blok A7B, Jl. Boulevard Artha Gading No.18, RT.18/RW.8, West Kelapa Gading, Kelapa Gading',
              addressCountry: 'Indonesia',
              addressRegion: 'Jakarta',
              addressLocality: 'Jakarta Utara'
            },
            sameAs: [
              'https://parentinghub.co.id',
              'https://www.instagram.com/parenting.hub/',
              'https://www.instagram.com/paninticom/'
            ],
            contactPoint: {
              '@type': 'ContactPoint',
              contactType: 'Customer Service',
              telephone: '+6282111308195'
            },
            knowsLanguage: 'id',
            legalName: 'PT Paninti Hub Indonesia',
            location: {
              '@type': 'Place',
              name: 'Indonesia',
              alternateName: 'Jakarta',
              map: 'https://goo.gl/maps/yVwDSidRzdv119xz7'
            },
            subOrganization: {
              '@type': 'Organization',
              name: 'Parenting Hub',
              logo: {
                '@type': 'ImageObject',
                url: 'https://paninti.com/_nuxt/img/logo-parenting-hub.3e801b4.svg'
              },
              url: 'https://parentinghub.co.id',
              location: 'Indonesia'
            }
          }
        },
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org/',
            '@type': 'WebSite',
            name: 'Paninti',
            url: 'https://paninti.com/',
            sameAs: [
              'https://www.instagram.com/parenting.hub',
              'https://www.instagram.com/paninticom/',
              'https://parentinghub.co.id'
            ],
            link: [{ rel: 'canonical', href: 'https://paninti.com' }],
            script: [
              {
                type: 'application/ld+json',
                json: {
                  '@context': 'https://schema.org/',
                  '@type': 'Organization',
                  name: 'Paninti',
                  url: 'https://paninti.com/',
                  logo: {
                    '@type': 'ImageObject',
                    url: 'https://paninti.com/img/logo/logo-paninti.svg'
                  },
                  description:
                    'Toko Perlengkapan Bayi, Ibu Hamil, dan Peralatan Masak',
                  address: {
                    '@type': 'PostalAddress',
                    postalCode: '14240',
                    streetAddress:
                      'Rukan Sentra Bisnis Blok A7B, Jl. Boulevard Artha Gading No.18, RT.18/RW.8, West Kelapa Gading, Kelapa Gading',
                    addressCountry: 'Indonesia',
                    addressRegion: 'Jakarta',
                    addressLocality: 'Jakarta Utara'
                  },
                  sameAs: [
                    'https://parentinghub.co.id',
                    'https://www.instagram.com/parenting.hub/',
                    'https://www.instagram.com/paninticom/'
                  ],
                  contactPoint: {
                    '@type': 'ContactPoint',
                    contactType: 'Customer Service',
                    telephone: '+6282111308195'
                  },
                  knowsLanguage: 'id',
                  legalName: 'PT Paninti Hub Indonesia',
                  location: {
                    '@type': 'Place',
                    name: 'Indonesia',
                    alternateName: 'Jakarta',
                    map: 'https://goo.gl/maps/yVwDSidRzdv119xz7'
                  },
                  subOrganization: {
                    '@type': 'Organization',
                    name: 'Parenting Hub',
                    logo: {
                      '@type': 'ImageObject',
                      url: 'https://paninti.com/_nuxt/img/logo-parenting-hub.3e801b4.svg'
                    },
                    url: 'https://parentinghub.co.id',
                    location: 'Indonesia'
                  }
                }
              },
              {
                type: 'application/ld+json',
                json: {
                  '@context': 'https://schema.org/',
                  '@type': 'WebSite',
                  name: 'Paninti',
                  url: 'https://paninti.com/',
                  sameAs: [
                    'https://www.instagram.com/parenting.hub',
                    'https://www.instagram.com/paninticom/',
                    'https://parentinghub.co.id'
                  ],
                  description:
                    'Toko Perlengkapan Bayi, Ibu Hamil, dan Peralatan Masak',
                  potentialAction: {
                    '@type': 'SearchAction',
                    target:
                      'https://paninti.com/products/all?search={search_term_string}',
                    'query-input': 'required name=search_term_string'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  }
}
